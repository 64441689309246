.cmp-header {
  background-color: $header-background;
  padding: 0 6%;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 100;
  transition: transform 300ms ease-in;
  position: fixed;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $header-background;
  }

  @include until($breakpoint-medium) {
    padding: 0 5% rem(10);

    .cmp-header__logo {
      transition: all 200ms ease;
    }

    .cmp-header__generic-nav-more {
      transition: all 200ms ease;
    }

    .cmp-header__main-nav-mobile-btn {
      transition: all 200ms ease;

      &::before,
      &::after {
        transition: all 200ms ease;
      }
    }

    &.header-small {
      background-color: transparent;

      &::before {
        backdrop-filter: blur(1px);
        background-color: rgba($header-background, 0.7);
      }

      .cmp-header__logo {
        min-height: 3.5rem;
        padding-top: .75rem;
      }

      .cmp-header__generic-nav-more {
        top: 1.125rem;
      }

      .cmp-header__main-nav-mobile-btn {
        top: 1.125rem;
        width: 2rem;
        height: 2rem;

        &::before,
        &::after {
          width: 1.3125rem;
          height: 1.3125rem;
        }
      }

      .cmp-header__logo a {
        transform: scale(.95);
      }

      .cmp-header__overlay--active {
        opacity: 1;
        background-color: white;
      }
    }
  }

  @include from($breakpoint-medium) {
    &.header-small {
      .cmp-header__main-nav {
        padding-top: 0;
        padding-bottom: 0;
      }

      .cmp-header__generic-nav-list-wrapper {
        max-height: 0;
      }

      .cmp-header__logo {
        min-height: 7.5rem;
        padding-top: 2rem;
      }
    }

    .cmp-header__main-nav,
    .cmp-header__generic-nav-list-wrapper,
    .cmp-header__logo {
      transition: all 300ms ease-in;
    }
  }
}

.cmp-header__logo {
  min-height: rem(200);
  padding-top: rem(34);
  padding-bottom: $header-logo-bottom-offset;
  display: flex;
  align-items: center;
  width: 23.8%;
  position: relative;
  z-index: 11;
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  img {
    height: auto;
  }

  a {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $header-logo-text-color;
    width: 100%;
    height: 100%;
  }

  @include until($breakpoint-large) {
    min-height: rem(180);
    padding-bottom: $header-logo-bottom-offset-medium;

    img {
      max-height: rem(34);
      width: auto;
    }
  }

  @include until($breakpoint-medium) {
    padding-top: rem(20);
    min-height: rem(80);
    padding-bottom: 0;
    position: relative;
    top: -$header-logo-bottom-offset-small;

    img {
      max-height: rem(26);
      width: auto;
    }
  }
}

.cmp-header__logo-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  font-weight: $font-weight-regular;
  font-size: 0.625rem;
  line-height: 1.143;
  padding-left: $header-logo-text-left-offset;
  padding-bottom: $header-logo-text-bottom-offset;
  white-space: nowrap;
  margin-left: $header-logo-right-offset;

  strong {
    font-weight: $font-weight-bold;
  }

  &::before {
    content: '';
    position: absolute;
    left: rem(-1);
    top: rem(-6);
    width: rem(2);
    height: calc(100% + #{rem(12)});
    background-color: $header-logo-line-color;

    @include until($breakpoint-large) {
      top: rem(-4);
      height: calc(100% + #{rem(8)});
    }

    @include until($breakpoint-medium) {
      top: rem(-2);
      height: calc(100% + #{rem(4)});
    }
  }

  @include until($breakpoint-large) {
    // font-size: $font-size-4;
    padding-left: $header-logo-text-left-offset-medium;
    padding-bottom: $header-logo-text-bottom-offset-medium;
    margin-left: $header-logo-right-offset-medium;
  }

  @include until($breakpoint-medium) {
    // font-size: $font-size-4-mobile;
    padding-left: $header-logo-text-left-offset-small;
    padding-bottom: $header-logo-text-bottom-offset-small;
    margin-left: $header-logo-right-offset-small;
  }
}

.cmp-header__logo--hidden {
  opacity: 0;
  pointer-events: none;
}

.cmp-header__navigation {
  width: 76.2%;
}

.cmp-header__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $black;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.5s cubic-bezier(0.33, 1, 0.68, 1);
  pointer-events: none;
}

.cmp-header__overlay--active {
  opacity: 0.5;
  pointer-events: auto;
}
